body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  background-color: #1b1b29;
}

.lightbar {
  box-shadow: 0 1px 0px 0 rgb(218 218 229 / 16%), 0 0px 1px 0 rgb(218 218 229 / 16%) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.background_gradient {
  background: #008AFF;
  background: -webkit-linear-gradient(top left, #008AFF, #1AD3FD);
  background: -moz-linear-gradient(top left, #008AFF, #1AD3FD);
  background: linear-gradient(to bottom right, #008AFF, #1AD3FD);
}

.blue_line {
  border-top: 1px ridge rgb(170, 50, 220, .6);
  border-top-color: #1b1b29;
  border-top: 5px;
}

@media screen and (max-width: 600px) {
  .hideonmobile {
    Display: none;
  }
}


.cent {
  justify-content: center!important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.9);
}

.carousel-caption {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav-item:hover {
  border-bottom: red !important;

}

/* .navbar.navbar-dark .nav-item .nav-link:hover {
   border-bottom: 12rem !important;
   border-bottom-color: #cc0000 !important;
   color: #CC0000!important; 
  } */

  .nav-link:hover{
    text-decoration: underline;
    text-decoration-color: rgba(255, 0, 0, 0.356);
    -moz-text-decoration-color: rgba(255, 0, 0, 0.356);
    text-decoration-thickness: 3px;
    text-underline-offset: 4px;
  }

  .loader {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  
  .loader:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loader 1.2s linear infinite;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .centered {
    position: fixed;
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
  }

  .aws-btn {

    --slider-height-percentage: 30%;
    --slider-transition-duration: 650ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 4px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #ffffff;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    }

    .awssld {
      --organic-arrow-thickness: 4px;
      --organic-arrow-height: 40px;
      --slider-height-percentage: 30% !important;
      --loader-bar-color: #851515;
      --loader-bar-height: 6px;
      --control-button-width: 10%;
      --control-button-height: 25%;
      --control-button-opacity: 0.5;
      --control-button-hover-opacity: 0.75;
      --control-button-background: transparent;
      --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
      --slider-transition-duration: 575ms;
      --organic-arrow-color: #6a6a6a;
      --organic-arrow-border-radius: 0;
      --control-bullet-color: #1266f1;
      --control-bullet-active-color: #fbfbfb;
      --content-background-color: #2f2f2f;
      color: #fff;
      ;

    }
      .awssld__bullets--active {
        transform: scale(1.5);
        background: #1266f1 !important;
    }

  .fl {
    border-color: rgba(255, 0, 0, 0.356) !important;
    max-width: 95%;
  }  

  